import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import { MdEdit } from "react-icons/md"
import Session from "../../../utils/session-info"
import Translate from "../../../i18n/translate"
import WizardService from "../wizard-service"
import ParametersModal from "./wizard-sql-parameters-modal"
import AceEditor from "react-ace"
import { setStepStatus } from "../../../store/app-state/actions"
import * as inputSelector from "../../../store/interfaces/reducer"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import 'ace-builds/src-noconflict/mode-sql'
import { setWizardState, setDeletedFields } from "../../../store/wizard/wizard-store-actions"
import { Grid2 } from "@mui/material"
import { StyledGrid, StyledButtonGrid, StyledContainer, StyledRow, StyledIconButton, StyledTitle } from "../../../componentsUI/styledComponents/styledWizardSQL"
import Loading from "../../../componentsUI/loading"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import ConectorTable from "../../../componentsUI/conectorTable"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConectorCheckbox from "../../../componentsUI/checkBox"
import { styled } from "@mui/material/styles"

class WizardSQL extends Component {
    constructor(props) {
        super(props);

        const {
            wizardState,
            dialogOptions,
            isLoading,
            query,
            inputs,
            inputOptions,
            markers
        } = props;

        const stepProperties = wizardState.eventProperties[wizardState.currentStep - 1];
        var currentParameters = [],
            parametersRemovedInCycle = [],
            stepStructure = wizardState.event.structures.find(struc =>
                struc.codEventProperty === stepProperties.codEventProperty
            );

        if (!stepStructure) {
            stepStructure = {
                codStructure: 0,
                codConnectionAction: wizardState.event.codConnectionAction,
                codConnectionType: wizardState.event.codConnectionType,
                codInstanceConnection: wizardState.event.codInstanceConnection,
                codEventProperty: stepProperties.codEventProperty,
                codInstance: Session().codInstance,
                structureDetails: [
                    {
                        codStructureDetail: 1,
                        indPosition: 1,
                        codInstance: Session().codInstance,
                        title: "root - sql",
                        codMaster: 0,
                        fields: []
                    }
                ],
                structureExtras: [],
                isMain: true,
                indPosition: 1,
                codMaster: 0
            }
            wizardState.event.structures.push(stepStructure);
            props.setWizardState(wizardState);
        } else {
            const detail = stepStructure.structureDetails[0];
            if (detail) {
                currentParameters = detail
                    .fields
                    .filter(field => field.desSubaction === "parameter")
                    .sort((field1, field2) => field1.indPosition - field2.indPosition)
                    .map(field => ({
                        ...field,
                        fieldAttributes: field.fieldAttributes || [],
                        desAttrType: field.desAttrType
                            ? field.desAttrType
                            : (field.fieldAttributes ? field.fieldAttributes.find(attr =>
                                attr.codAttribute === 1
                            ).desValue : Translate("uninformed"))
                    }));
            }
        }

        this.state = {
            markers,
            inputs,
            inputOptions,
            stepStructure,
            dialogOptions,
            currentParameters,
            wizardState,
            parametersRemovedInCycle,
            query,
            isLoading,
            dataSource: [...currentParameters],
            gridOptions: {
                multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                noDataMessage: Translate("select_input_template"),
                columns: [
                    {
                        title: "position",
                        field: "indPosition"
                    },
                    {
                        title: "name",
                        field: "desField"
                    },
                    {
                        title: "type",
                        field: "desAttrType"
                    },
                    {
                        title: "function",
                        field: "desSubaction",
                        cellFilter: "capitalize"
                    }
                ],
                refresh: this.refresh.bind(this)
            },
            stepProperties: wizardState.eventProperties[wizardState.currentStep - 1]
        }
    }

    setMarkers = () => {
        var { currentParameters, selectedParameter, markers } = this.state,
            { classes } = this.props,
            { value } = this.refs.ace.props,
            valueAsRows = value.split("\n");

        if (!selectedParameter) {
            return;
        }

        const targetIndPosition = currentParameters.findIndex(param =>
            param.indPosition === selectedParameter.indPosition
        );

        let matches = 0,
            position = {};

        loop1:
        for (let i = 0; i < valueAsRows.length; i++) {
            const row = valueAsRows[i];
            for (let j = 0; j < row.length; j++) {
                if (row.substring(j, j + 3) === "{P}") {
                    if (matches === targetIndPosition) {
                        position = {
                            row: i,
                            startColumn: j
                        }
                        break loop1;
                    }
                    matches = matches + 1;
                }
            }
        }

        markers = [];
        if (position.hasOwnProperty("row") && position.hasOwnProperty("startColumn")) {
            markers.push({
                startRow: position.row,
                endRow: position.row,
                startCol: position.startColumn,
                endCol: position.startColumn + 3,
                type: "text",
                //className: classes.highlight
            });
        }

        this.setState({ markers });
    }

    refresh = selectedRow => {
        this.setState({
            selectedParameter: selectedRow
        }, this.setMarkers);
    }

    componentWillReceiveProps = props => {
        this.setState({ wizardState: { ...props.wizardState } });
    }

    componentWillMount = _ => {
        var {
            wizardState,
            stepProperties,
            stepStructure,
            inputs,
            inputOptions
        } = this.state,
            stepInputs = [],
            structureInInput;

        inputOptions = inputs.filter(event => event.structures);

        inputOptions.forEach(event => {
            event.structures.forEach(struc => {
                if (struc.structureDetails) {
                    const outOption = struc.structureDetails.find(detail =>
                        detail.output
                    );
                    if (outOption) {
                        stepInputs.push(outOption);
                    }
                }
            });
        });

        if (stepStructure.structureDetails.length) {
            const stepDetail = stepStructure.structureDetails[0];
            if (stepDetail.idDetailInput) {
                structureInInput = stepInputs.find(input =>
                    input.idStructureDetail === stepDetail.idDetailInput
                );
                this.getInputProps(structureInInput.codStructure)
            }
        }

        let codConnectionMode;
        if (wizardState.event.instanceConnection) {
            codConnectionMode = wizardState.event.instanceConnection.codConnectionMode
        } else {
            codConnectionMode = 0;
        }

        WizardService
            .GetAttrGroupsByCodConnectionActionCodEventProp(
                wizardState.event.codConnectionAction,
                codConnectionMode,
                stepProperties.codProperty
            ).then(attributeGroups => {
                //fazer isso em um get só
                this.setState({
                    isLoading: false,
                    attributeGroups
                }, (() => {
                    var attrGroups = [],
                        attrGroupCodes = attributeGroups.map(attrGroup =>
                            attrGroup.AttributeGroupCodes
                        ),
                        attrGroupParam = attributeGroups.find(attr =>
                            attr.DesSubaction === "parameter"
                        );

                    attrGroupCodes.forEach(attrArr => {
                        attrGroups = [...attrGroups, ...attrArr];
                    });

                    var attributeIds = {
                        paramAttributes: [],
                        queryAttributes: []
                    };

                    attrGroups.forEach(
                        (attrGroup) => {
                            if (attrGroupParam
                                && attrGroup.codAttributeGroup === attrGroupParam.CodAttributeGroup) {
                                attributeIds.paramAttributes.push(attrGroup.codAttribute)
                            } else {
                                attributeIds.queryAttributes.push(attrGroup.codAttribute)
                            }
                        }
                    );

                    attributeIds.paramAttributes = [
                        ...new Set(attributeIds.paramAttributes)
                    ];

                    attributeIds.queryAttributes = [
                        ...new Set(attributeIds.queryAttributes)
                    ];

                    WizardService.GetAttributesByIdArray([
                        ...attributeIds.queryAttributes,
                        ...attributeIds.paramAttributes
                    ]).then(attrs => {
                        let masterAttrs = attrs
                            .filter(attr => !attr.CodMaster)
                            .map(masterAttr => {
                                masterAttr.indPosition = attrGroups.find(attr =>
                                    attr.codAttribute === masterAttr.codAttribute
                                ).indPosition;

                                return masterAttr;
                            })
                            .sort((o1, o2) => o1.indPosition - o2.indPosition);
                        attrs.forEach(attr => {
                            if (attr.CodMaster) {
                                let master = masterAttrs.find(parent =>
                                    parent.codAttribute === attr.CodMaster
                                );
                                if (!master.options) {
                                    master.options = [attr];
                                } else {
                                    master.options.push(attr);
                                }
                            }
                        });

                        var { query } = this.state;

                        if (stepStructure.structureDetails
                            && stepStructure.structureDetails.length) {
                            const detail = stepStructure.structureDetails[0]
                            const queryField = detail.fields.find(field =>
                                field.desSubaction === "query"
                            );

                            if (queryField) {
                                query = queryField.fieldAttributes[0].desValue;
                            }
                        }

                        this.setState({
                            queryAttributes: masterAttrs.filter(m =>
                                attributeIds.queryAttributes.includes(m.codAttribute)
                            ),
                            parametersAttributes: masterAttrs.filter(m =>
                                attributeIds.paramAttributes.includes(m.codAttribute)
                            ),
                            query,
                            stepInputs,
                            structureInputOptions: stepInputs.map(option => {
                                return {
                                    label: option.output,
                                    value: option.codStructure,
                                    codStructureDetail: option.idStructureDetail
                                }
                            }),
                            structureInInput
                        });
                    })
                }).bind(this));
            });
    }

    disabledStep = () => {
        const {
            query,
            wizardState,
            structureInInput,
            currentParameters
        } = this.state;

        const parameterWithoutSelectedInput = () => {
            return !Boolean(structureInInput) && currentParameters.length;
        }

        const noQueryProvided = () => {
            return !Boolean(query.length)
        }

        const parameterWithoutConfiguration = () => {
            if (!Boolean(structureInInput) || !currentParameters.length) {
                return false;
            }

            const withoutConfiguration = currentParameters.filter(parameter => !parameter.fieldAttributes.length);

            return Boolean(withoutConfiguration.length);
        }

        let isDisabled = noQueryProvided()
            || parameterWithoutSelectedInput()
            || parameterWithoutConfiguration();
        this.props.setStepStatus(wizardState.currentStep, !isDisabled);
        return isDisabled;
    }

    onChange = (query) => {
        const self = this;

        clearTimeout(self.delay);

        self.delay = setTimeout(() => {
            var {
                attributeGroups,
                currentParameters
            } = self.state;
            let { event } = self.state.wizardState;

            const attrGroupParam = attributeGroups.find(attr =>
                attr.DesSubaction === "parameter"
            );

            function scanContent(query) {
                return (query.match(/{P}/ig) || []).length;
            }

            function newParameter(indPosition, paramCache = {}) {
                let parameter = {
                    desField: paramCache.desField || `${Translate("new_parameter")} - ${indPosition}`,
                    attributeGroup: attrGroupParam,
                    codAttributeGroup: attrGroupParam.CodAttributeGroup,
                    desSubaction: attrGroupParam.DesSubaction,
                    desAttrType: paramCache.desAttrType || Translate("uninformed"),
                    fieldAttributes: paramCache.fieldAttributes || [],
                    indPosition
                };

                if (paramCache.hasOwnProperty("codField")) {
                    parameter.codField = paramCache.codField;
                    parameter.codInstance = paramCache.codInstance;
                }

                return parameter;
            }

            var paramCount = scanContent(query);
            if (paramCount != currentParameters.length) {
                var parameters = [];

                for (var r = 0; r < paramCount; r++) {
                    let currentParameter = currentParameters.shift();

                    parameters.push(newParameter(r, currentParameter));
                }

                currentParameters.map(currentParamenter => {
                    if (currentParamenter.codField) {
                        event.deletedFields.push(currentParamenter.codField);
                    }
                });

                self.props.setWizardState({ event });

                parameters.sort((o1, o2) => o1.indPosition - o2.indPosition);

                self.setState({
                    currentParameters: parameters,
                    dataSource: [...parameters]
                }, self.setMarkers);
            }
        }, 500);

        self.setState({
            query: query.replace(/{p}/g, "{p}".toUpperCase()),
        });
    }

    editParameter = _ => {
        const { dialogOptions } = this.state;
        this.setState({
            dialogOptions: {
                ...dialogOptions,
                open: true,
                handleClose: this.callBackModal,
                handleDismiss: this.dismissModal
            },
        });
    }

    callBackModal = selectedParameter => {
        const { dataSource, dialogOptions, gridOptions } = this.state;

        dataSource[selectedParameter.indPosition] = selectedParameter;
        delete gridOptions.selectedRow;

        this.setState({
            currentParameters: [...dataSource],
            dataSource,
            gridOptions,
            dialogOptions: {
                ...dialogOptions,
                open: false
            },
        })
    }

    dismissModal = _ => {
        const { dialogOptions } = this.state;
        this.setState({
            dialogOptions: {
                ...dialogOptions,
                open: false
            }
        });
    }

    componentWillUnmount = _ => {
        const {
            query,
            dataSource,
            stepStructure,
            queryAttributes,
            attributeGroups,
            structureInInput
        } = this.state;

        var detail,
            queryField;

        if (stepStructure.structureDetails.length) {
            detail = stepStructure.structureDetails[0]
        }

        queryField = detail.fields.find(field => field.desSubaction === "query");

        if (structureInInput) {
            detail.idDetailInput = structureInInput.idStructureDetail;
            detail.input = structureInInput.output;
        }

        var paramsTratados = dataSource
            .map(param => {
                param.idStructureDetail = detail.codStructureDetail
                param.codInstance = detail.codInstance
                return param;
            })

        if (!queryField) {
            queryField = {
                idStructureDetail: detail.codStructureDetail,
                desField: "query",
                indPosition: 0,
                codAttributeGroup: attributeGroups.find(attrGroup =>
                    attrGroup.DesSubaction === "query"
                ).CodAttributeGroup,
                codInstance: Session().codInstance,
                desSubaction: "query",
                fieldAttributes: [
                    {
                        codInstance: Session().codInstance,
                        desValue: query,
                        codAttribute: queryAttributes[0].codAttribute,
                        indPosition: 0,
                        desSubaction: "query"
                    }
                ]
            }
        } else {
            queryField.desField = "query";
            queryField.fieldAttributes[0].desValue = query;
        }

        detail.fields = [...paramsTratados, queryField];
        stepStructure.structureDetails = [detail];
        this.props.setWizardState({ stepStructure });
    }

    handleChangeInputSelect = e => {
        var { structureInInput, stepInputs } = this.state;

        if (structureInInput
            && e.codStructureDetail === structureInInput.idStructureDetail) {
            return;
        }

        structureInInput = stepInputs.find(option =>
            e.codStructureDetail === option.idStructureDetail
        );

        this.setState({ structureInInput }, _ => {
            this.getInputProps(structureInInput.codStructure)
        });
    }

    getInputProps = codStructure => {
        WizardService
            .GetDetailsAndInputsByCodStructure(codStructure)
            .then(({
                detailsFields = []
            }) => {
                this.setState({ detailsFields });
            });
    }

    changeCheckboxStructureExtra = () => {
        const { wizardState } = this.state;

        let structureDetailsExtras = wizardState.event.structures[0].structureDetails[0].structureExtras

        if(structureDetailsExtras == undefined || structureDetailsExtras.length == 0){
            let extra = {
                codInstance: wizardState.event.structures[0].codInstance,
                codStructure: wizardState.event.structures[0].codStructure,
                codStructureExtra: 800,
                codStructureExtraValues: 0,
                codStructureDetail: 0,
                value: "true"
            }

            wizardState.event.structures[0].structureDetails[0].structureExtras = new Array
            wizardState.event.structures[0].structureDetails[0].structureExtras.push(extra)
        } else structureDetailsExtras[0].value == "true" ? structureDetailsExtras[0].value = "false" : structureDetailsExtras[0].value = "true" 

        this.setState({wizardState})
    }

    getCheckboxStructureExtra = () => {
        const { wizardState } = this.state;

        let structureDetailsExtras = wizardState.event.structures[0].structureDetails[0].structureExtras

        if(structureDetailsExtras == undefined || structureDetailsExtras.length == 0) return false
        else {
            return structureDetailsExtras[0].value == "true" ? true : false
        }
    }

    isNotInsertConnectionAction = () => {
        return this.state.wizardState.event.connectionAction.uniqueDescription != 'insert' ? true : false
    }

    render() {
        var {
            query,
            markers,
            isLoading,
            dataSource,
            wizardState,
            gridOptions,
            dialogOptions,
            detailsFields,
            structureInInput,
            selectedParameter,
            parametersAttributes,
            structureInputOptions
        } = this.state;

        if (isLoading) {
            return (<Loading />);
        }

        const disabledStep = this.disabledStep();

        if (disabledStep !== wizardState.disabledStep) {
            this.props.setWizardState({ disabledStep })
        }

        return (
            <Fragment>
    
                    <Grid2 container spacing={1}>
                        <Grid2 size={6}>
                            <StyledContainer>
                                <StyledTitle>
                                    {Translate("query")}
                                </StyledTitle>
                                <StyledGrid>
                                    <AceEditor
                                        ref="ace"
                                        mode="sql"
                                        placeholder={Translate("describe_query_executed")}
                                        onChange={this.onChange.bind(this)}
                                        fontSize={20}
                                        highlightActiveLine={true}
                                        height={"500px"}
                                        width={"100%"}
                                        markers={markers}
                                        useSoftTabs={true}
                                        name="query"
                                        value={query}
                                        editorProps={{ $blockScrolling: true }}/>
                                </StyledGrid>
                            </StyledContainer>
                        </Grid2>
                        <Grid2 size={6}>
                            <StyledContainer>
                                <StyledTitle>
                                    {Translate("parameters")}
                                </StyledTitle>
                                <StyledRow>
                                    <StyledGrid item size={9}>
                                        <ConectorSelect
                                            name={"model-select"}
                                            label={"input_model"}
                                            placeholder={"choose"}
                                            options={structureInputOptions || []}
                                            value={
                                                structureInInput
                                                    ? structureInputOptions.find(option =>
                                                        option.codStructureDetail === structureInInput.idStructureDetail
                                                    )
                                                    : null
                                            }
                                            onChange={this.handleChangeInputSelect.bind(this)}/>
                                    </StyledGrid>
                                    <StyledButtonGrid>
                                        <StyledIconButton
                                            disabled={
                                                !selectedParameter
                                                || (selectedParameter && !Object.keys(selectedParameter).length)
                                                || !structureInInput
                                            }
                                            onClick={this.editParameter}
                                            size="small"
                                            name={"edit-field"}
                                            variant="outlined"
                                            className={"iconNoMargin"}>
                                            <MdEdit size={24}/>
                                        </StyledIconButton>
                                    </StyledButtonGrid>
                                </StyledRow>
                                <StyledGrid>
                                    <ConectorTable dataSource={dataSource} gridOptions={gridOptions} connectSelectedRows={this.refresh.bind(this)}/>
                                    <ConectorCheckbox
                                        invisible={this.isNotInsertConnectionAction()}
                                        checked={this.getCheckboxStructureExtra()}
                                        onChange={this.changeCheckboxStructureExtra.bind(this)}
                                        label={Translate("submissionDespiteErrors")}/>
                                </StyledGrid>
                            </StyledContainer>
                        </Grid2>
                    </Grid2>

                <ParametersModal
                    selectedParameter={{ ...selectedParameter }}
                    dialogOptions={dialogOptions}
                    detailsFields={detailsFields}
                    attributes={parametersAttributes}/>
            </Fragment>
        )
    }
}

WizardSQL.defaultProps = {
    dialogOptions: {
        open: false,
        title: "parameter_definition",
        message: "parameter_settings",
        type: "confirmation"
    },
    query: "",
    parametersAttributes: [],
    queryAttributes: [],
    dataSource: [],
    inputOptions: [],
    isLoading: true,
    markers: []
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getEvents(store);
    return {
        inputs,
        wizardState: wizardStore.getWizardState(store)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: wizardState => dispatch(
            setWizardState(wizardState)
        ),
        setDeletedFields: deletedFields => dispatch(
            setDeletedFields(deletedFields)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
    }
}

const Highlight = styled('div')(({ theme }) => ({
    position: "absolute",
    backgroundColor: "#bbe0efbf"
}))

WizardSQL = connect(mapStateToProps, mapDispatchToProps)(WizardSQL)

export default WizardSQL;
import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import Session from "../../utils/session-info"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import { Grid2 } from "@mui/material"
import Toast from "../../components/toast/toast"
import ConectorSelect from "../../componentsUI/conectorSelect"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import CancelSaveButtons from "../../componentsUI/cancelSaveButtons"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import InputText from "../../componentsUI/inputText"
import AddEditAccessRelease from "./access-releases-service"
import Loading from "../../componentsUI/loading"

class PermissionDetails extends Component {
    constructor(props) {
        super(props);

        let accessreleaseID = window.location.hash.substring(16)

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "permissions",
            url: "#/accessrelease"
        }, {
            label: (accessreleaseID !== "add" ? "edit_access" : "set_up_new_access")
        }]);

        this.state = {
            accessreleaseID: accessreleaseID,
            schedule: {},
            search: "",
            searchHelp: Translate("enter_email"),
            userFound: null,
            editPermission: false,
            isReady: (accessreleaseID === "add"),
            validations: [],
            condition: null,
            loading: true,
            permission: {
                codUser: null,
                codProfile: null,
                codCompany: Session().codCompany,
                codInstance: Session().codInstance,
                isDefault: false
            }
        };
    }

    componentWillMount() {
        let { accessreleaseID } = this.state
        let isEditing = false

        this.getProfiles()
        
        if (accessreleaseID !== "add") {
            this.getPermission(accessreleaseID)
            isEditing = true
        }

        this.setState({
            ...this.state,
            editPermission: isEditing
        })
    }

    getPermission = id => {
        var params = id.split("&")
        let codUser = params[0]
        let codProfile = params[1]

        AddEditAccessRelease.GetUserProfile(codUser, codProfile)
            .then(data => {
                data.profile = data.codProfile
                this.setState({
                    ...this.state,
                    permission: data,
                    entityLoaded: true,
                    search: data.User.desName
                })
            })
            .catch(error => {
                Toast.error(Translate(error.message))
                this.redirectToHome()
            })
    }

    getProfiles = () => {
        Promise.all([
            AddEditAccessRelease.GetProfiles(),
            AddEditAccessRelease.GetUserPermissionAccessLevel()
        ])
            .then(([profiles, userAccessLevel]) => {
                if ([2, 4].includes(userAccessLevel.accessLevel)) return profiles
                else {
                    Toast.error(Translate("notHavePermissionToAccess"))
                    this.redirectToHome()
                }
            })
            .then(profiles => {
                this.setState({
                    ...this.state,
                    profiles
                }, () => this.setLoadingPage(false))
            })
            .catch(error => {
                Toast.error(Translate(error.message))
                this.redirectToHome()
            })
    }

    setLoadingPage = loading => this.setState({ loading })

    redirectToHome = () => window.location.replace('#/')

    cancel = _ => {
        window.location.replace('#/accessrelease')
    }

    save = _ => {
        let validations = []
        this.setState({ ...this.state, validations })
        let permission = { ...this.state.permission }

        if (!permission.codUser) {
            validations.push({
                field: Translate("user_email"),
                description: Translate("enter_user_email")
            })
        }

        if (!permission.codProfile) {
            validations.push({
                field: Translate("Profile"),
                description: Translate("choose_profile")
            })
        }

        if (validations.length) {

            this.setState({ ...this.state, validations })
            return

        } else {
            if (!this.state.editPermission) {
                AddEditAccessRelease.InsertUserProfile(permission)
                    .then(onSuccess.bind(this))
            } else {
                AddEditAccessRelease.EditUserProfile(permission)
                    .then(onSuccess.bind(this))
            }

            function onSuccess(res) {
                res.json().then(function (data) {
                    var msgFromDB = data != null ? data.msg : null
                    if (res.ok) {
                        Toast.success(msgFromDB != null ? msgFromDB : Translate("record_success_saved"))
                        window.location.replace('#/accessrelease')
                    } else {
                        Toast.error(msgFromDB != null ? msgFromDB : Translate("error_saving_record"))
                    }
                })
            }
        }
    }

    isReady = _ => {
        let { accessreleaseID } = this.state

        return accessreleaseID !== "add" ? !!this.state.entityLoaded : !!this.state.typesLoaded
    }

    handleChange = event => {
        let state = { ...this.state }

        switch (event.target.name) {
            case "user_email":
                state.search = event.target.value
            default:
                state.permission[event.target.name] = event.target.value
        }

        this.setState(state)
    }

    handleChangeSelect = (value) => {
        let state = { ...this.state }
        state.permission.codProfile = value.value
        this.setState(state)
    }

    makeOptionsList = list => {
        var options = list.map(r => {
            return {
                value: r.codProfile,
                label: r.description
            }
        })
        return options
    }

    search = () => {
        const { search, permission } = this.state
        const desEmail = search
        const codCompany = permission.codCompany
        const codInstance = permission.codInstance

        AddEditAccessRelease.GetUserWithoutProfileByEmail(desEmail, codCompany, codInstance)
            .then(onSuccess.bind(this))

        function onSuccess(res) {
            var self = this

            if (res.status == 500) Toast.error(Translate("invalidEmail"))
            else {
                res.json().then(function (data) {
                    var msgFromDB = data != null ? data.msg : null
                    if (res.ok) {
                        if (msgFromDB != null) {
                            let state = { ...self.state }
                            state.condition = "warning"
                            state.userFound = null
                            state.searchHelp = msgFromDB
                            self.setState(state)
                        } else {
                            let state = { ...self.state }
                            state.userFound = data.desName
                            state.permission.codUser = data.codUser
                            state.condition = "success"
                            state.searchHelp = Translate("user_found")
                            self.setState(state)
                        }
                    } else {
                        let state = { ...self.state }
                        if (msgFromDB != null) {
                            state.condition = "error"
                            state.searchHelp = msgFromDB
                        }
                        state.userFound = null
                        self.setState(state)
                    }
                })
            }
        }
    }

    disableSaveButton = () => {
        const { permission } = this.state

        return permission.codProfile == null ? true : false
    }

    render() {
        const { permission, profiles, validations, accessreleaseID, loading } = this.state
        const options = !!profiles ? this.makeOptionsList(profiles) : []

        if (loading) return <Loading />
        else return (
            <Fragment>
                <InternalPageTitle title={accessreleaseID !== "add" ? Translate("edit_access") : Translate("set_up_new_access")} />

                <StyledPaper>
                    <Grid2 container spacing={2}>
                        <Grid2 size={8}>
                            <InputText
                                label={Translate("user_email")}
                                help={this.state.searchHelp}
                                name={"user_email"}
                                type={"text"}
                                value={this.state.search}
                                disabled={permission.codUser != null}
                                onChange={this.handleChange}
                                condition={this.state.condition}
                                endIcon={"search"}
                                endTooltip={Translate("search")}
                                endActionButton={this.search}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorSelect
                                label={Translate("profile")}
                                help={Translate("enter_type")}
                                placeholder={" "}
                                name={"codProfile"}
                                onChange={this.handleChangeSelect}
                                value={options.find(option => option.value === permission.codProfile)}
                                options={options}
                                className={validations.find(validation => validation.field === Translate("profile")) ? "error" : ""}
                                disabled={permission.codUser == null}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 container justifyContent="flex-end">
                        <CancelSaveButtons 
                            onCancel={this.cancel} 
                            onSave={this.save}
                            disableSaveButton={this.disableSaveButton()}/>
                    </Grid2>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(PermissionDetails)
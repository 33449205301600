import React, { Component, Fragment } from 'react'
import Translate from "../../i18n/translate"
import { DialogActions, Grid2 } from "@mui/material"
import { StyledDialogTitle } from "../../componentsUI/styledComponents/styledDialog"
import { StyledDialogZIndex } from "../../componentsUI/styledComponents/styledConnectionsCopyModal"
import ConectorTable from "../../componentsUI/conectorTable"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import InputText from "../../componentsUI/inputText"
import ConectorCheckbox from "../../componentsUI/checkBox"

class ConnectionHeaderParams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialog: { open: false },
            modal: { open: false },
            headerParams: props.headerParams,
            deletedHeaderParams: props.deletedHeaderParams,
            selectedParam: {
                parameterName: undefined,
                parameterValue: undefined
            },
            gridOptions: {
                selectedRow: [],
                multiSelect: false,
                hideBtnDelete: false,
                hideBtnEdit: false,
                hideBtnCopy: true,
                hideFilter: true,
                sortField: "parameterName",
                columns: [{
                    title: "name",
                    field: "parameterName"
                }, {
                    title: "value",
                    field: "parameterValue"
                }, {
                    title: "authenticationHeader",
                    field: "authenticationHeader",
                    cellFilter: "boolean"
                }]
            }
        }
    }

    openDeleteDialog = () => {
        this.setState({
            dialog: {
                type: "danger",
                open: true,
                message: "remove_records",
                title: "exclusion_confirmation",
                onClose: () => this.deleteHeaderParam(),
                onDismiss: () => this.setState({ 
                    dialog: { open: false }, 
                    selectedParam: {parameterName: undefined, parameterValue: undefined}
                })
            }
        })
    }

    deleteHeaderParam = () => {
        const { selectedParam, deletedHeaderParams, headerParams, gridOptions } = this.state

        let updatedHeaderParams = headerParams.filter(headerParam => {
            return headerParam.codConnectionHeaderParameter != selectedParam.codConnectionHeaderParameter
        })

        deletedHeaderParams.push(selectedParam.codConnectionHeaderParameter)

        this.setState({
            dialog: { open: false }, 
            selectedParam: {parameterName: undefined, parameterValue: undefined},
            headerParams: updatedHeaderParams, 
            deletedHeaderParams,
            gridOptions:{...gridOptions, selectedRow: []}
        }, () => this.updateConnectionHeaderParams())
    }

    saveHeaderParam = () => {
        const { selectedParam, headerParams } = this.state

        if(selectedParam.codConnectionHeaderParameter === undefined) headerParams.push(selectedParam)
        else {
            headerParams.find(headerParam => {
                if(headerParam.codConnectionHeaderParameter == selectedParam.codConnectionHeaderParameter){
                    headerParam.parameterName = selectedParam.parameterName
                    headerParam.parameterValue = selectedParam.parameterValue
                    headerParam.authenticationHeader = selectedParam.authenticationHeader
                }
            })
        }

        this.setState({ 
            modal: { open: false }, 
            selectedParam: {parameterName: undefined, parameterValue: undefined},
            headerParams
        }, () => this.updateConnectionHeaderParams())
    }

    updateConnectionHeaderParams = () => {
        const { headerParams, deletedHeaderParams } = this.state

        this.props.updateConnectionHeaderParams(headerParams, deletedHeaderParams)
    }

    renderAddEditParamModal = () => {
        const { modal, selectedParam } = this.state

        return (
            <StyledDialogZIndex fullWidth={true} maxWidth={"sm"} open={modal.open}>
                <StyledDialogTitle id="confirmation-dialog-title" className="confirmation">
                    {Translate("newOrEditParameter")}
                </StyledDialogTitle>
                <Grid2 container justifyContent="center" spacing={1}>
                    <Grid2 size={11}>
                        <InputText
                            label={Translate("parameterName")}
                            value={selectedParam.parameterName}
                            onChange={element => {
                                this.setState({ selectedParam: { ...selectedParam, parameterName: element.target.value } })
                            }}/>
                        <InputText
                            label={Translate("parameterValue")}
                            value={selectedParam.parameterValue}
                            onChange={element => {
                                this.setState({ selectedParam: { ...selectedParam, parameterValue: element.target.value } })
                            }}/>
                        <Grid2 size={10}>
                            <ConectorCheckbox
                                checked={selectedParam.authenticationHeader}
                                label={Translate("sendAuthenticationHeader")}
                                onChange={element => {
                                    this.setState({selectedParam: { 
                                        ...selectedParam, 
                                        authenticationHeader: element.target.checked
                                    }})
                                }}/>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={() => this.setState({ modal: { open: false }, selectedParam: {parameterName: undefined, parameterValue: undefined}})}>
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={() => this.saveHeaderParam()}>
                        {Translate("save")}
                    </StyledButton>
                </DialogActions>
            </StyledDialogZIndex>
        )
    }

    renderParamsTable = () => {
        const { gridOptions, headerParams } = this.state

        return (
            <ConectorTable
                gridBtn={"addParameter"}
                gridNewItem={() => {
                    this.setState({ modal: { open: true } })
                }}
                gridEditItem={param => {
                    this.setState({ selectedParam: param, modal: { open: true } })
                }}
                gridDeleteItem={param => {
                    this.setState({ selectedParam: param }, this.openDeleteDialog.bind(this))
                }}
                gridOptions={gridOptions}
                dataSource={headerParams}>
            </ConectorTable>
        )
    }

    render = () => {
        const { dialog } = this.state

        return (
            <Fragment>
                {this.renderParamsTable()}
                {this.renderAddEditParamModal()}
                <ConectorDialogConfirmation dialogOptions={dialog}/>
            </Fragment>
        )
    }
}

export default ConnectionHeaderParams